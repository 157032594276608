/* Main properties */
$footer-height: 220px;  // Default footer height

html {
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  margin-bottom: $footer-height;
  height:100%;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

main {
  position: relative;
  min-height: 100%;
}

span { /* Texts are contained in span, so this should fix the visualization bug on some ugly browsers like Mozilla Night Basalmici Version */
  font-family: "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}


/* Fix bootstrap outline when click */
a:active,
a:focus,
button:active,
button:focus,
.ms-Button:active,
.ms-Button:focus {
  outline: 0;
  outline: none;
  box-shadow: none !important;
}


/* Responsive footer */
@media only screen and (max-width: 1199px) {
  body {
    margin-bottom: $footer-height + 17px;
  }
}

@media only screen and (max-width: 992px) {
  body {
    margin-bottom: $footer-height + 140px;
  }
}

@media only screen and (max-width: 767px) {
  body {
    margin-bottom: $footer-height + 380px;
  }

  footer .row {
    text-align: center;
  }

  footer .ms-Toggle-innerContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 100px;
  }

  footer .ms-Dropdown {
    margin-left: auto;
    margin-right: auto;
    max-width: 120px !important;
  }

  footer .ms-FocusZone {
    margin-left: auto;
    margin-right: auto;
    max-width: 215px !important;
  }

  footer .contacts .text, footer .main-col .text {
    margin-left:25%;
    margin-right: 25%;
  }
}


@media only screen and (max-width: 363px) {
  body {
    margin-bottom: $footer-height + 381px;
  }
}


/* Responsive menu and title */
@media only screen and (min-width: 587px) {
  header .headerRow {
    margin-left: 1rem!important;
    margin-right: 1rem !important;
    margin-bottom: 0 !important;
  }
  .header-menu .pivot {
    display: block;
  }

  .header-menu .dropdown {
    display: none;
  }
}

@media only screen and (max-width: 586px) {
  header .headerRow {
    margin-left: 15px !important;
    margin-right: 15px !important;
    margin-bottom: 0 !important;
  }

  .header-menu .pivot {
    display: none;
  }

  .header-col {
    justify-content: right;
    display: flex;
    align-items: center;
  }

  .header-menu .ms-Dropdown-title {
    display: none;
  }

  .header-menu .ms-Dropdown-container {
    float:right;
    width:30px;
    margin-right: 5px;
  }

  .header-menu .ms-Dropdown-caretDownWrapper {
    right: 6px;
  }
}




/* Dropdown style for header-menu */
.header-menu .ms-Dropdown-title { 
  border-style: none;
  height: 44px;
  justify-content: center;
  text-align: center;
}


/* Grid system adjustments for filter container */
@media only screen and (max-width: 766px) {
  .groups-list .col-sm-12 {
    max-width: 450px;
    text-align: center;
  }
}


/* Align text when in mobile view */
@media only screen and (max-width: 992px) {
  .center-mobile {
    text-align: center !important;
  }
}


/* Section adjustments */
@media only screen and (max-width: 350px) {
  .ms-Panel-headerText {
    font-size: 21px !important;
  }
}

@media only screen and (max-width: 575px) {
  .organization .col-12, .contributors .col-12 {
    max-width: 350px;
  }

  .administrators .title {
    margin-right:15%;
    margin-left:15%;
  }

  .groups-list .col-xs-12 {
    max-width: 350px;
    text-align: center;
  }

  .representatives .people-list .col-xs-12, .groups .admin-list .col-xs-12 {
    margin-left:15%;
    max-width: 250px;
    text-align: center;
  }

  .representatives .people-list, .groups .admin-list {
    justify-content: center;
  }

  .col-persona {
    max-width: 230px;
    margin-left:10%;
  }

  .rules .col-12 {
    max-width: 250px;
  }

  .home .telegram-advantages-list {
    margin-left: 20% !important;
  }
}

@media only screen and (max-width: 380px) {
  .services .ms-Card {
    min-width:100px;
  }
}


/* Adjustment for mobile-view ms-card in groupsList and additionalGroupsView */
@media only screen and (min-width: 501px) {
  .course-list .listGridTile, .additional-groups .listGridTile {
    text-align: center;
    outline: none;
    position: relative; 
    float: left;
  }
}

@media only screen and (max-width: 500px) {
  .course-list .ms-Card, .additional-groups .ms-Card {
    width: 100%;
    max-width:100%;
  }

  .course-list .listGridTile, .additional-groups .listGridTile {
    width: 100% !important;
    max-width: 100%;
  }
}


/*---------------------
  Additional properties
---------------------*/

@media only screen and (max-width: 640px) { /* Dropdown items in mobile visualization when using icons act buggy. Must create issue in fluentUi repo */
  .ms-Dropdown-item {
    margin-bottom: 10px !important;
  }
}

.groups .filters-toggle .ms-Toggle { /* Fix useless margin for toggle component for filters enabled in groups section */
  margin-bottom: 0px !important;
}

.groups .filters-toggle .ms-Label {
  margin-left: 8px;
  margin-right: 8px;
}

@media only screen and (max-width: 310px) { /* A fix for better responsive behaviour in toggle component of groups filters section */
  .groups .filters-toggle .ms-Label {
    max-width: 90px; 
    margin-right: 0px;
  }
}

.rules .ms-Button-flexContainer {
  margin: 7px;
}

.header-menu .ms-Dropdown-title {
  line-height: 45px;
  padding:0;
}

.additional-groups .ms-List, .course-list .ms-List { /* Padding for card-list in groups and additionalGroups sections */
  padding: 3px;
}

.services .ms-CardSection {
  text-align: left;
}

.ms-TooltipHost { /* For showing tooltip correctly on settings icon */
  display: inline;
}

/* This was used for Persona component without text
.organization .ms-Persona {
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
}
*/

.contributors .ms-Persona {
  width: 48px;
}

.MuiChip-label {
  font-family: "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 11px;
}

.organization .ms-Card {
  min-width: 100px !important;
}

.course-list .ms-Persona-primaryText {
  font-weight: 600;
}

/* MessageBar component adjustments */
.ms-MessageBar-dismissSingleLine {
  display: none;
}

.ms-MessageBar-text {
  display: block;
  text-align: center;
}

/* ChoiceGroup adjustments to not show hover effect */
.degree-informations .ms-ChoiceField-field:hover::before, .university-links .ms-ChoiceField-field:hover::before,
.degree-informations .ms-ChoiceField-field:focus::before, .university-links .ms-ChoiceField-field:focus::before {
  opacity: 0;
}

.degree-informations .ms-ChoiceField-field:hover::after, .university-links .ms-ChoiceField-field:hover::after
.degree-informations .ms-ChoiceField-field:focus::after, .university-links .ms-ChoiceField-field:focus::after {
  opacity: 0;
}

.degree-informations .ms-ChoiceField-labelWrapper, .university-links .ms-ChoiceField-labelWrapper {
  overflow: visible;
}

.degree-informations .ms-ChoiceFieldGroup-flexContainer {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .university-links .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
    justify-content: center;
  }
}


/* Dialog component adjustments */
.ms-Dialog-main {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 400px !important;
}

@media only screen and (max-width: 480px) {
.ms-Dialog-main {
    width: auto !important;
    margin-left:10px;
    margin-right:10px;
  }
}

/* Header Adjustments */
header .ms-Image-image {
  display: inline;
  margin-bottom: 9px !important;
}

.header-panel {
  .ms-Panel-main {
    width: 80%;
  }

  /*
  .ms-Panel-content {
    height: 90vh;
    display: flex;
    align-items: center;
  }
  */
}

/* Fix searchBox visualization for small height displays */
@media only screen and (max-height: 610px) {
  #SuggestionContainer .ms-Callout-main {
    max-height: 200px !important;
  }
}

@media only screen and (max-height: 500px) {
  #SuggestionContainer .ms-Callout-main {
    max-height: 150px !important;
  }
}

@media only screen and (max-height: 410px) {
  #SuggestionContainer .ms-Callout-main {
    max-height: 100px !important;
  }
}

/* Footer adjustments */
footer .ms-Label {
  padding-top: 0px;
}

/* Fix image being cut */
.home .ms-Image {
  overflow: visible;
}

@media only screen and (max-width: 350px) {
  .first-section img {
    width: 120px;
  }
}

/* Some services adjustments */
.services .ms-Card {
  align-items: stretch !important;
}

.services .ms-DocumentCardPreview, .services .ms-DocumentCardPreview-iconContainer {
  min-height: 100%;
}